.profile-card {
    max-width: 400px;
    margin: 20px auto;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .profile-card .card-header {
    background-color: #f8f9fa;
    padding: 20px;
  }
  
  .profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #dee2e6;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #6c757d;
  }
  
  .profile-item {
    margin-bottom: 10px;
  }
  
  .profile-label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .profile-value {
    color: #6c757d;
  }