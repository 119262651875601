.home-header {
  color: #007bff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

main {
  background-color: #f8f9fa ;
}

.footer {
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}