/* 공통 스타일 */
.header-container {
  padding: 20px;
  text-align: left;
  width: 100%;
}

.main-title {
  font-family: 'Arial Black', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -1px;
  text-align: left;
}

.subtitle {
  font-family: 'Apple SD Gothic Neo', sans-serif;
  font-size: 12px;
  color: #888;
}

.search-section {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-bar-container {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50px;
  padding: 5px 10px;
  width: 100%;
  max-width: 500px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
}

.search-btn {
  border: none;
  background-color: white;
  padding: 0;
}

/* 반응형 스타일 */
@media (min-width: 768px) {
  .main-title {
    font-size: 36px;
  }

  .subtitle {
    font-size: 14px;
  }

  .search-bar-container {
    padding: 5px 15px;
  }
}

@media (max-width: 768px) {
  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-title {
    text-align: center;
    font-size: 24px;
  }

  .subtitle {
    text-align: center;
  }

  .search-section {
    margin-bottom: 10px; /* 모바일에서는 각 섹션 간 여백 추가 */
  }

  .d-flex {
    flex-direction: column; /* 모바일에서 세로 정렬 */
    align-items: center;
  }
}
